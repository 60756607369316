fp-logo {

}

md-content {
    padding-top: 25px;
}

md-toast {

    .md-toast-content {
        color: #fff !important;
    }

    &.md-fp-simple-toast-success-theme {
        .md-toast-content {
            background-color: #4caf50 !important;
        }
    }

    &.md-fp-simple-toast-warn-theme {
        .md-toast-content {
            color: #333 !important;
            background-color: #ffeb3b !important;
        }
    }

    &.md-fp-simple-toast-error-theme {
        .md-toast-content {
            background-color: #f44336 !important;
        }
    }
}


.help-text .md-button {
    padding: 0;
    margin: 0;
    min-width: 0;
    text-transform: none;
}

.help-text > p {
    color: #828282;
}


// Predefined md-icon sizes
md-icon {
    &.size-16 {
        width: 16px !important;
        height: 16px !important;
    }
    &.size-24 {
        width: 24px !important;
        height: 24px !important;
    }
    &.size-32 {
        width: 32px !important;
        height: 32px !important;
    }
    &.size-36 {
        width: 36px !important;
        height: 36px !important;
    }
    &.size-64 {
        width: 64px !important;
        height: 64px !important;
    }
}


.md-headline {
    margin-block-end: 5px;
    font-size: 20px;
    font-weight: bold;
    margin-left: 10px;
}

md-tabs.md-default-theme .md-tab.md-active, md-tabs .md-tab.md-active, md-tabs.md-default-theme .md-tab.md-active md-icon, md-tabs .md-tab.md-active md-icon, md-tabs.md-default-theme .md-tab.md-focused, md-tabs .md-tab.md-focused, md-tabs.md-default-theme .md-tab.md-focused md-icon, md-tabs .md-tab.md-focused md-icon {
    color: #4A90E2 !important;
}

md-tabs.md-default-theme md-ink-bar, md-tabs md-ink-bar {
    color: #37C25A !important;
    background: #37C25A !important;
}




// md-card seems to be buggy, when handling different orientation of buttons in md-card-actions.
md-card {
    box-shadow: 0px 8px 16px rgba(74, 74, 74, 0.16);
    border-radius: 4px;
    md-card-title {
        padding: 10px 16px 16px;
    }
    md-card-actions {
        flex-direction: column !important;
        .md-primary {
            text-transform: none !important;
            text-decoration: underline !important;
        }

        &.layout-xs-column, &.layout-sm-column, &.layout-lg-column, &.layout-gt-xs-column, &.layout-gt-sm-column {

            .md-button {
                margin-left: initial !important;
                margin-right: initial !important;
                margin: 2px 0 !important;
            }
        }

    }
}

.legal-navbar {
    ul {
        list-style: none !important;
    }
    a {
        color: #4A90E2 !important;
        font-family: Roboto !important;
        font-style: normal !important;
        font-weight: normal !important;
        font-size: 14px !important;
        line-height: 30px !important;
    }
    .selected {
        a {
            color: #333 !important;
            text-decoration: none;
            font-weight: 500;
        }
    }
}

md-card.legal {
    a {
        color: #4A90E2 !important;
        font-family: Roboto !important;
        font-style: normal !important;
        font-weight: normal !important;
    }
}


.bullet {
    width: 0;
    height: 0;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-left: 6px solid #4A90E2;
    position: relative;
    float: left;
    display: block;
}




// Custom mdTabs used as carousel like UI element
md-tabs {
    &[fp-card-flow][hide-tab-bar] {
        md-tabs-wrapper {
            display: none;
        }
    }
}

settings-menu {
    md-list {
        background-color: #fff;
        border-right: 2px solid rgba(130,130,130,0.2);
        padding-right: 25px;
    }

    md-list-item.fp-menu-item-selected a {
        background-color: rgba(74, 144, 226, 0.3);
        border-radius: 7px !important;
    }

    .md-list-item-text {
        color: #828282;
    }

    md-list-item.fp-menu-item-selected {
        .md-list-item-text {
            color: #333;
        }

        .md-secondary-container {
            border: solid #4A90E2;
            border-width: 0 2px 2px 0;
            display: inline-block;
            padding: 5px;
            transform: rotate(-45deg);
        }
    }
}

.md-subheader.larger-text {
    .fpop-media-layout-gt-xs & {
        font-size: 16px;
    }
}




/*
    General Cards
    We use cards on many sites
*/
md-card.fp-card {

    @media screen and (max-width: 768px) {
        margin-right: 4px;
        margin-left: 4px;
        //background: #EEF;
    }

    &.mobile-padding {
        /* Angular Material - Mobile Helper */
        md-card-content {
            @media screen and (max-width: 768px) {
                padding: 8px;
            }
        }

        md-card-title {
            @media screen and (max-width: 768px) {
                padding: 16px 12px 12px;
            }
        }
    }

    md-card-title-text {
        .md-headline {
            margin-top: 0px;
        }

        .md-title {
            margin: 0px;
        }
    }

    md-card-title {
        &.low-profile {
            padding: 16px;

            h1,h2,h3,h4,h5,h6 {
                margin: 0;
                padding: 0;
            }
        }

        md-icon.fp-large {
            width: 48px;
            height: 48px;
        }
    }

    md-card-content, div {
        &.pull-up {
            margin-top: -70px;
        }
    }


    // Custom Extensions
    &.fixed-height-320 {
        height: 320px;
        min-height: 320px;
        max-height: 320px;
    }

}



/*
    Status Indicator
*/
md-dialog.fpop-status-indicator {
    background-color: transparent;
    box-shadow: none;
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    position: relative;

    md-progress-circular {
        margin: auto;
    }


    // md-progress-linear

    .progress-box {
        // background-color: rgba( 0,0,0,0.1 );
        // border-radius: 20%;
        margin: auto;
        width: 60%;
        .ui.progress .bar > .progress {
            color: #555;
        }
    }

    // .info-text {
    //     position: absolute;
    //     margin: auto;
    //     top: 0;
    //     left: 0;
    //     right: 0;
    //     bottom: 0;
    // }
}


md-dialog.num-transfer-terms {
    max-width: 600px;
}


md-list-item.fp-menu-item-selected a {
    background-color: rgba(34,166,227,0.22);
}

// Align Hamburger button to left better
.md-button.md-icon-button.fp-left-sidenav-toggle,
.fp-left-sidenav-toggle {
    margin-left: -10px;
}

.fp-md-header {
    height: 60px;
}

.fp-md-header .menuItems .md-button.fp-nav-item-selected,
.fp-nav-item-selected {
    background-color: #D00B00;
    border-bottom: 5px solid #FFC62A;
}

footer {
    box-shadow: 0px -4px 9px rgba(0, 0, 0, 0.05);
    z-index: 0;
    border-top: 1px solid rgba(130, 130, 130, 0.2);
    color: #828282,
}



// md-toolbar
md-toolbar {
    &.md-small,
    &.md-small .md-toolbar-tools {
        height: 40px;
        min-height: 40px;
        max-height: 40px;
    }
}

md-switch.md-default-theme.md-checked:not([disabled]).md-primary .md-thumb, md-switch.md-checked:not([disabled]).md-primary .md-thumb {
    background-color: #37C25A !important;
}

md-switch.md-default-theme.md-checked:not([disabled]).md-primary .md-bar, md-switch.md-checked:not([disabled]).md-primary .md-bar {
    background-color: #37C25A !important;
    opacity: 0.5 !important;
}


// Sub Nav
sub-nav {
    z-index: 0;

    md-toolbar {
        back-nav {
            margin-left: -20px;
        }
    }
}




h4 + md-input-container {
    margin-top: -18px;
}


md-input-container + .ui.pointing.label {
    margin-top: -18px;
}


// Custom Composite Fields / Directives / Components
// timezone-input {
//     md-content,
//     md-autocomplete,
//     md-autocomplete-wrap,
//     input {
//         background-color: transparent;
//     }
//
//
//     md-autocomplete {
//         height: 41px;
//     }
//
//
//     .md-whiteframe-1dp,
//     .md-whiteframe-z1 {
//         box-shadow: none;
//     }
//
//
//     md-autocomplete input:not(.md-input) {
//         border: 1px solid rgba(0, 0, 0, 0.117647);
//         border-width: 0 0 1px 0;
//     }
//
//
//     button {
//         position: absolute;
//         top: 0;
//         right: 0;
//     }
//
// }



/* XEditable Styles & Overrides */
.editable-wrap {
    // This is important for small views using xeditable (see my/settings page)
    // You will need to override the controlsTpl in custom directives, also
    // pay attention to fields that require a large height, you many need to override
    // &.standard-field-height:not(.editable-md-switch, .editable-username-change, .editable-password-change) {
    //     max-height: 52px;
    // }

    &.editable-cc-exp > md-input-container {
        margin-top: 0;
    }

    username-change-field,
    password-change-field {
        md-input-container {
            margin: 0;
        }
    }


    md-input-container {
        ng-messages {
            display: block;
        }
    }

}


.editable-buttons > .md-button {
    margin: auto 0;
}

md-input-container.editable-controls [ng-message] {
    position: static;
}

// form.ng-invalid {
//     border: 1px solid red;
//
// }

// md-input-container.editable-controls {
//     padding: 0;
//     margin-top: 0;
// }




.md-button.fp-small {
    min-width: initial;
}

.md-button.fpop-menu-button {
    padding-right: 18px;
}

.md-button.fpop-menu-button:after {
    display: block;
    content: '\25BC';
    speak: none;
    font-size: 13px;
    transform: scaleY(0.5) scaleX(1) translateY(50%);
    position: absolute;
    right: 12px;
    top: 0;
}


md-input-container > fpop-help  {
    position: absolute;
    top: 8px;
    right: auto;
}



/*
    Carousel (md-tab / fp-card-flow)
*/

md-tabs[ fp-card-flow ] {
    position: relative;

    .fp-navdot {
        position: absolute;
        bottom: 2%;
        right: auto;
        left: 50%;
        margin: auto;
        transform: translateX( -50% ) scale( 1 );
    }

    // .fp-navdot ul {}
    // .fp-navdot li {}
}
// Responsive dots scale down in small viewport
.fpop-media-layout-sm .fp-navdot.fp-navdot-scaleup,
.fpop-media-layout-xs .fp-navdot.fp-navdot-scaleup {

    bottom: 1%;
    transform: translateX( -50% ) scale( 0.6 );

    li a {
        border: 1px solid rgba(0, 0, 0, .6);
    }
}


// Carousel Prev/Next Buttons
.fp-card-flow-next,
.fp-card-flow-prev {

    md-icon {
        fill: #777;
    }

    position: absolute;
    top: 50%;
    transform: translateY( -50% );
}

.fp-card-flow-prev {
    left: 1%;
}

.fp-card-flow-next {
    right: 1%;
}



/*
    Corner Alert
    TODO: Needs to be able to do both left & right placements
*/
fpop-corner-alert {
    outline: none;

    .corner.label {
        outline: none;

    	md-icon {
            position: absolute;
            top: 8px;
    		fill: #fff;
    		width: 16px;
    		height: 16px;

    		svg {

    			width: 16px;
    			height: 16px;
    		}
    	}

        &.left {
            md-icon {
                left: 8px;
            }
        }

        &.right {
            md-icon {
                right: 0;
            }
        }
    }
}

fpop-corner-alert,
.fpop-corner-alert {
    .pointing.label {

        white-space: nowrap;
        opacity: 0.92;

        &.right,
        &.left {
            &:before {
                top: 12px !important;
            }
        }
    }

}




/*
    MD LIST with Flag icons (for country)
*/

// md-select

.fp-country-list {

}

// md-select
.fp-country-list,
.fp-country-list-items {


    .fp-flag-icon {
        display: inline-block;
        vertical-align: sub;
        border-radius: 3px;
        margin-right: 0.4em;
    }

}










